<template>
    <div class="px-page mt-5 mb-5">
        <v-stepper v-model="step">
            <v-stepper-header>
                <v-stepper-step :complete="step > 1" step="1">Konfigurieren</v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step :complete="step > 2" step="2">Bestellung prüfen</v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step step="3">Abgeschlossen</v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
                <v-stepper-content step="1">
                    <v-row class="ml-2 mr-2">
                        <v-col md="12">
                            Kerne
                            <v-slider v-model="coresOption" min="0" :max="coresValues.length - 1" :tick-labels="coresValues" tick-size="0" />
                        </v-col>
                        <v-col md="12">
                            Arbeitspeicher
                            <v-slider v-model="memoryOption" min="0" :max="memoryValues.length - 1" :tick-labels="memoryValues.map(e => e + '')" tick-size="0" />
                        </v-col>
                        <v-col md="12">
                            Festplatte
                            <v-slider v-model="diskOption" min="0" :max="diskValues.length - 1" :tick-labels="diskValues.map(e => e + ' GB')" tick-size="0" />
                        </v-col>
                        
                        <v-col md="12">
                            Betriebssystem
                            <v-select v-model="template" :items="templates.map(t => ({ text: t.title , value: t.id}))" />
                        </v-col>

                        <v-col md="12">
                            Laufzeit
                            <v-select v-model="duration" :items="durationValues.map(v => ({ text: v + ' Tage', value: v }))" />
                        </v-col>
                        <v-col md="12" class="text-center" style="font-size: 25px; font-weight: 500;">
                            Preis: {{ this.price.toFixed(2).replace('.',',') }} €
                        </v-col>
                        <v-col md="12" class="text-center" style="font-size: 25px; font-weight: 500;">
                            <v-btn v-if="$store.state.current_user" large color="primary" @click="step = 2">Bestellung prüfen</v-btn>
                            <div v-else>
                                <v-btn large color="primary" @click="bus.$emit('login:show')">Anmelden</v-btn>
                                <v-btn large color="primary" @click="bus.$emit('register:show')" class="ml-2">Registrieren</v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-stepper-content>
                <v-stepper-content step="2" class="text-center">
                    <table style="width: 100%; border-collapse: collapse; font-size: 20px;" class="text-left">
                        <tbody>
                            <tr>
                                <td>Basispreis</td>
                                <td class="text-right">{{ priceDisplay('base', 1) }} €</td>
                            </tr>
                            <tr>
                                <td>{{ cores }} Kerne</td>
                                <td class="text-right">{{ priceDisplay('cores', cores) }} €</td>
                            </tr>
                            <tr>
                                <td>{{ memory }} GB Arbeitsspeicher</td>
                                <td class="text-right">{{ priceDisplay('memory', memory) }} €</td>
                            </tr>
                            <tr>
                                <td>{{ disk }} GB Festplatte</td>
                                <td class="text-right">{{ priceDisplay('disk', disk) }} €</td>
                            </tr>
                            <tr>
                                <td>Betriebssystem: {{ (templates.filter(t => t.id == template)[0] || { title: 'Keins' }).title }}</td>
                                <td class="text-right"></td>
                            </tr>
                            <tr>
                                <td style="border-top: 1px solid black;">Laufzeit</td>
                                <td style="border-top: 1px solid black;" class="text-right">{{duration}} Tage</td>
                            </tr>
                            <tr>
                                <td style="border-top: 1px solid black; padding-top: 5px;"><strong>Gesamt</strong></td>
                                <td style="border-top: 1px solid black; padding-top: 5px;" class="text-right"><strong>{{ priceDisplay('total') }} €</strong></td>
                            </tr>
                        </tbody>
                    </table>
                    <v-btn color="primary" large class="mt-5 mr-2" @click="step = 1" :disabled="ordering">Konfiguration ändern</v-btn>
                    <v-btn color="primary" large class="mt-5" v-if="$store.state.current_user.balance >= price" @click="order()" :disabled="ordering">Kostenpflichtig bestellen <v-progress-circular v-if="ordering" class="ml-2" indeterminate /></v-btn>
                    <v-btn color="primary" large class="mt-5" v-else @click="bus.$emit('recharge:show')">Guthaben aufladen</v-btn>
                </v-stepper-content>
                <v-stepper-content step="3" class="text-center pt-5 pb-5">
                    Bestellung abgeschlossen<br><br>
                    <v-icon color="green" size="50">mdi-check</v-icon><br><br>
                    <v-btn color="primary" @click="$router.push({ name: 'dashboard-rootserver', params: { id: server.id } })">Zum RootServer</v-btn>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
    </div>
</template>

<script>
import xeonserv from '../../../apis/xeonserv'

export default {
    data() {
        return {
            step: 1,
            pricing: {
                base: 0,
                cores: 0,
                memory: 0,
                disk: 0,
                addresses: 1,
            },
            coresValues: [1,2,3,4,5,6,7,8,9,10,12],
            memoryValues: [1,2,4,6,8,10,12,14,16,18,20,24,32,36,48,64],
            diskValues: [10,20,30,40,50,100,150,200,250,300,500],
            durationValues: [30,90,180,360], 
            coresOption: 0,
            memoryOption: 0,
            diskOption: 0,
            addresses: 1,
            duration: 30,
            ordering: false,
            server: {
                id: 0
            },
            templates: [],
            template: 0
        }
    },
    created() {
        xeonserv().get('/pricing/ROOT_SERVER').then(res => this.pricing = res.data.data)
        xeonserv().get('/rootserver-templates').then(res => {
                this.templates = res.data.data
                this.template = this.templates[0].id
            })
    },
    computed: {
        cores() {
            return this.coresValues[this.coresOption]
        },
        memory() {
            return this.memoryValues[this.memoryOption]
        },
        disk() {
            return this.diskValues[this.diskOption]
        },
        
        price() {
            return (this.pricing.base + (this.cores * this.pricing.cores) + (this.disk * this.pricing.disk) + (this.memory * this.pricing.memory)) * (this.duration / 30)
        }
    },
    methods: {
        order() {
            this.ordering = true
            xeonserv().post('/rootservers', {
                cores: this.cores,
                disk: this.disk,
                memory: this.memory,
                addresses: this.addresses,
                template_id: this.template,
                duration: this.duration
            }).then(res => {
                this.server = res.data.data
                this.step = 3
                this.$store.dispatch('loadCurrentUser')
            }, () => this.ordering = false)
        },
        priceDisplay(prop, amount) {
            const p = prop == 'total' ? this.price : (amount * this.pricing[prop] * (this.duration / 30))
            return p.toFixed(2).replace('.', ',')
        }
    }
}
</script>